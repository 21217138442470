<template>
  <van-tabs class="grey" v-model="active" color="#33CCCC" animated swipeable sticky>
    <van-tab class="grey" title="推荐咨询">
      <div class="content" :style="{minHeight: contentHeight}">
        <ArticleItem v-for="index in 20" :key="index">aaaa</ArticleItem>
      </div>
    </van-tab>
    <van-tab class="grey" title="信用卡小课堂">
      <div class="content" :style="{minHeight: contentHeight}">内容 2</div>
    </van-tab>
    <van-tab class="grey" title="空间">
      <div class="content" :style="{minHeight: contentHeight}">内容 3</div>
    </van-tab>
  </van-tabs>
</template>

<script>
import ArticleItem from "@/components/ArticleItem.vue";

export default {
  name: "Article",
  components: {
    ArticleItem
  },
  data() {
    return {
      active: 0,
      contentHeight: "0px"
    };
  },
  created: function() {
    this.contentHeight = window.screen.height - 44 + "px";
  }
};
</script>

<style lang="scss">
.content {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
.grey,
.van-tabs__nav {
  background: #f7f7f7;
}
</style>
