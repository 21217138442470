<template>
  <div class="item" @click="goto">
    <div class="left">
      <img
        height="110px"
        width="110px"
        src="http://img.zcool.cn/community/01051955434ac00000019ae9a9a369.jpg@2o.jpg"
      />
    </div>
    <div class="right">
      <div class="title">信用卡不得不防的四个坑</div>
      <div class="note">小星星小星星小星星小星星小星星小星星小星星小星星</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleItem",
  created: function() {},
  methods: {
    goto: function() {
      this.$router.push({ path: "/article/11" });
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  background: #fff;
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
}
.left {
  display: inline-block;
  margin-right: 10px;
}
.right {
  text-align: left;
  padding-right: 30px;
  .title {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .note {
    color: #e5e5e5;
    font-size: 10px;
  }
}
</style>